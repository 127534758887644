import _ from "lodash"
import { METHODS } from "../../consts/methods.consts"
import { useGetQuery } from "../../services/method-api.service"
import PrintedLine from "./components/PrintedLine"

const PrintedList = () => {
    const { data } = useGetQuery({
        method: METHODS.GET_PRINTING_SESSION_LIST,
        body: {},
        providesTags: ["LIST"]
    })

    const sessionList = _.get(data, 'message', [])

    const sessionMarkup = sessionList.map((line: any, index: any) => <PrintedLine key={index} {...line} />)

    return (
        <div className="h-[calc(100vh-153px)] flex flex-col gap-2 overflow-x-hidden overflow-y-auto py-2.5">
            {_.isEmpty(sessionList) && <h1 className="text-center"> No printing sessions found </h1>}
            {!_.isEmpty(sessionList) && sessionMarkup}
        </div>
    )
}

export default PrintedList