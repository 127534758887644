import RouterConfigs from "./routes/route-config";
import { BrowserRouter } from "react-router-dom";

function App() {

    return (
        <BrowserRouter>
            <RouterConfigs />
        </BrowserRouter>
    );
}

export default App;
