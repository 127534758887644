import { Box, DefaultCommandBar, Stack, TitleBar } from "@dladio/core-ui";
import { useNavigate } from "react-router-dom";
import env from '../../../package.json';
import { InvoiceList, PrintedList } from "../../components";
import { useLocalStore } from "@dladio/hooks";
import _ from "lodash";
import { useEffect, useState } from "react";
import { METHODS } from "../../consts/methods.consts";
import { useGetQuery, usePostMutation } from "../../services/method-api.service";
import PrintedInvoiceList from "../../components/invoice-list/PrintedInvoiceList";

const PrintQueue = () => {
    const navigate = useNavigate()
    const { select: selectPrintQueue, setStore } = useLocalStore("printQueue")
    const { model } = useLocalStore("printTypes")
    const [generatePDF] = usePostMutation()

    const { data, isLoading } = useGetQuery({
        method: METHODS.GET_TODAY_INVOICE_LIST,
        body: {},
        providesTags: ['LIST']
    })

    const invoiceData: any = _.get(data, 'message', [])

    useEffect(() => {
        setStore("selectedInvoices", invoiceData)
    }, [JSON.stringify(invoiceData)])

    const allInvoices = selectPrintQueue("selectedInvoices") || []
    const selectedInvoices: any = _.filter(allInvoices, { 'IsChecked': 1 })

    const cmdBarConfig = [
        {
            key: 1,
            text: "back",
            iconProps: { iconName: 'Back' },
            onClick: () => navigate(-1)
        },
        {
            key: 2,
            text: 'Start',
            iconProps: { iconName: 'SwitcherStartEnd' },
            onClick: async () => {
                generatePDF({
                    method: METHODS.GENERATE_PDF_BUNDLE,
                    body: {
                        'invoice_list': selectedInvoices,
                        'print_types': model
                    },
                    invalidatesTags: ["LIST"]
                })
            },
        }
    ]

    return (
        <>
            <TitleBar title="DLAD Print Queue"
                subTitle={`Helios core theme v${env?.dependencies?.["@dladio/core-ui"]?.replace('^', '')}`} />
            <DefaultCommandBar actions={cmdBarConfig} />

            <Stack>
                <Box title={`Invoice (${selectedInvoices.length}/${allInvoices.length})`}>
                    <InvoiceList data={allInvoices} isLoading={isLoading} />
                </Box>

                <Box title="Printed List">
                    <PrintedInvoiceList />
                </Box>

                <Box title="Print Sessions">
                    <PrintedList />
                </Box>
            </Stack>
        </ >
    );
}


export default PrintQueue