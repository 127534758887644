import { useEffect, useState } from "react"

const useSocket = (socket: any, event: string) => {
    const [message, setMessage] = useState<any>()
    useEffect(() => {
        if (socket != null) {
            socket?.on(event, (_message: any) => {
                setMessage(_message)
            })
        }
    }, [])

    return { message }
}

export default useSocket