import { Spinner } from "@fluentui/react";
import InvoiceItem from "./components/InvoiceItem";
import Filter from "./components/filter/Filter";
import _ from "lodash";

const InvoiceList = ({ data = [], isLoading = false }) => {
    const invoiceMarkup = data.map((line: any, index: any) => <InvoiceItem
        key={index} {...line} />)

    return (
        <>
            <Filter />
            <div className={"flex flex-col mt-2 gap-2 h-[calc(100vh-216px)] overflow-x-hidden overflow-y-auto"}>
                {isLoading && <Spinner />}
                {(_.isEmpty(data) && !isLoading) ? <p className="text-center">No invoices found</p> : invoiceMarkup}
            </div>
        </>
    )
}

export default InvoiceList