import _ from "lodash";
import { METHODS } from "../../consts/methods.consts";
import { useGetQuery } from "../../services/method-api.service";
import PrintedInvoiceItem from "./components/PrintedInvoiceItem";
import { Spinner } from "@fluentui/react";

const PrintedInvoiceList = () => {
    const { data, isLoading } = useGetQuery({
        method: METHODS.GET_TODAY_PRINTED_INVOICE_LIST,
        body: {},
        providesTags: ["LIST"]
    })

    const invoiceList = _.get(data, 'message', [])

    const invoiceMarkup = invoiceList.map((line: any, index: any) => <PrintedInvoiceItem
        key={index} {...line} />)

    return (
        <>
            <div className={"flex flex-col mt-2 gap-2 h-[calc(100vh-162px)] overflow-x-hidden overflow-y-auto"}>
                {isLoading && <Spinner />}
                {(_.isEmpty(data) && !isLoading) ? <p className="text-center">No invoices found</p> : invoiceMarkup}
            </div>
        </>
    )
}

export default PrintedInvoiceList