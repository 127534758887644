import _ from "lodash";
import erpMethodApi from "@dladio/service/dist/services/erp-method-api.service";

export const extendedMethodApi = erpMethodApi.injectEndpoints({
    endpoints: builder => ({
        get: builder.query({
            query: ({ method, body, providesTags = [] }) => ({
                url: `${method}`,
                method: 'POST',
                body
            }),
            providesTags: (result, error, arg) => arg?.providesTags
        }),
        post: builder.mutation<any, any>({
            query: ({ method, body, invalidatesTags = [] }) => ({
                url: `${method}`,
                method: 'POST',
                body: body
            }),
            invalidatesTags: (result, error, arg) => arg?.invalidatesTags
        })
    })
})

export const {
    useGetQuery,
    usePostMutation,
} = extendedMethodApi