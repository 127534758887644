import PrintQueue from "../pages/print-queue/PrintQueue";
import { useRoutes } from "react-router-dom";
import { CoreUIProvider } from "@dladio/core-ui";
import SocketManager from "../components/socket-manager/SocketManager";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: (
                <CoreUIProvider>
                    <SocketManager />
                    <PrintQueue />
                </CoreUIProvider>
            )
        }
    ]);

    return element;
};

export default RouterConfigs;