import { useLocalStore } from "@dladio/hooks";
import { Checkbox } from "@fluentui/react";
import _ from "lodash";
import React, { useEffect } from "react";

const InvoiceItem = ({ CardName, DocDate, DocNumber, DocTotal, IsChecked = false }: any) => {
    const [isChecked, setIsChecked] = React.useState<boolean>(Boolean(IsChecked));
    const { select, setStore } = useLocalStore("printQueue")

    const selectedInvoiceList = select("selectedInvoices") ?? []

    useEffect(() => {
        setIsChecked(Boolean(IsChecked))
    }, [IsChecked])

    const onCheckboxChange = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsChecked(!!checked);

            const index = _.findIndex(selectedInvoiceList, function (o: any) { return o.DocNumber == DocNumber; });
            setStore(`selectedInvoices.${index}.IsChecked`, checked ? 1 : 0)

            if (!checked) setStore("isAllSelected", false)
        }, [],
    );

    return (
        <div className={"rounded border w-full bg-gray-100 flex items-center gap-3 p-3"}>
            <Checkbox checked={isChecked} onChange={onCheckboxChange} />
            <div className="flex justify-between items-start">
                <div>
                    <h1 className={"font-bold"}>{DocNumber}</h1>
                    <h1>{DocDate}</h1>
                    <h1>{CardName}</h1>
                    <h1>{DocTotal}</h1>
                </div>
            </div>
        </div>
    )
}

export default InvoiceItem