interface IPrintedLineStat {
    label: string,
    value: any
}

const PrintedLinesStat = ({ label, value }: IPrintedLineStat) => {
    return (
        <div className="flex flex-col border border-gray-400 rounded p-2">
            <small>{label}</small>
            <p className="font-semibold text-center">{value}</p>
        </div>
    )
}

export default PrintedLinesStat