import _ from "lodash"
import { io } from "socket.io-client"
import { useToast } from "@chakra-ui/react"
import { useEffect } from "react"
import useSocket from "../../hooks/use-socket.hook"
import { useDispatch } from "react-redux"
import { extendedMethodApi } from "../../services/method-api.service"
import { SOCKET_IO } from "../../consts/app.consts"

export const socket = io(SOCKET_IO!, { withCredentials: true })

const SocketManager = () => {
    const toast = useToast()
    const dispatch = useDispatch()
    const { message } = useSocket(socket, 'app')

    useEffect(() => {
        socket.connect()
    }, [])

    useEffect(() => {
        if (typeof message == 'object' && 'type' in message) {
            switch (message.type) {
                case 'toast':
                    makeToast(message)
                    break;
                default:
                    break;
                case 'invalidate':
                    dispatch(extendedMethodApi.util.invalidateTags(message?.tags))
                    break;
            }
        }
    }, [message])

    const makeToast = (message: any) => {
        'payload' in message && toast({ ...message.payload })
    }

    return <></>
}

export default SocketManager

