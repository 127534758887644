const PrintedInvoiceItem = ({ CardName, DocDate, DocNumber, DocTotal }: any) => {
    return (
        <div className={"rounded border w-full bg-gray-100 flex items-center gap-3 p-3"}>
            <div className="flex justify-between items-start">
                <div>
                    <h1 className={"font-bold"}>{DocNumber}</h1>
                    <h1>{DocDate}</h1>
                    <h1>{CardName}</h1>
                    <h1>{DocTotal}</h1>
                </div>
            </div>
        </div>
    )
}

export default PrintedInvoiceItem