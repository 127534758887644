import { Spinner } from "@fluentui/react"
import PrintedLinesStat from "./PrintedLineStat"

const PrintedLine = ({ file_url, name, base_url, total_print_count, successful_print_count, unsuccessful_print_count, date, status }: any) => {
    const remainingPrintCount = (Number(total_print_count) - (Number(successful_print_count) + Number(unsuccessful_print_count)))

    const onDownloadClick = () => {
        window.open(`${base_url}${file_url}`, '_blank')
    }

    return (
        <div className="p-5 bg-gray-100 border rounded">
            <div className="flex items-center justify-between">
                <div className="flex flex-col">
                    <p>Id : {name}</p>
                    <p>Date : {date}</p>
                </div>
                {(status == 'Processing') ? <Spinner /> : null}
                {file_url && <p onClick={onDownloadClick} className="text-orange-500 cursor-pointer text-xs font-semibold">Download</p>}
            </div>

            <div className="grid grid-cols-4 gap-2 mt-2">
                <PrintedLinesStat label="Documents" value={total_print_count} />
                <PrintedLinesStat label="Generated" value={successful_print_count} />
                <PrintedLinesStat label="Remaining" value={remainingPrintCount} />
                <PrintedLinesStat label="Failed" value={unsuccessful_print_count} />
            </div>
        </div>
    )
}

export default PrintedLine;