import { localStoreThunk, useLocalStore } from "@dladio/hooks";
import { Checkbox } from "@fluentui/react";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Filter = () => {
    const [isSIChecked, setIsSIChecked] = React.useState<boolean>(true);
    const [isPSChecked, setIsPSChecked] = React.useState<boolean>(true);
    const { setStore } = localStoreThunk("printTypes")
    const { setStore: set, select } = useLocalStore("printQueue")
    const dispatch: any = useDispatch()

    const isAllSelected = select("isAllSelected") || false
    let list = select("selectedInvoices") || []

    useEffect(() => {
        if (_.every(list, { 'IsChecked': 1 })) set("isAllSelected", true)
        else set("isAllSelected", false)
    }, [JSON.stringify(list)])


    useEffect(() => {
        dispatch(setStore("SI", isSIChecked))
        dispatch(setStore("PS", isPSChecked))
    }, [isPSChecked, isSIChecked])

    const onChangeSi = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsSIChecked(!!checked);
        }, [],
    );

    const onChangePi = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            setIsPSChecked(!!checked);
        }, [],
    );

    const onChangeAllSelected = React.useCallback(
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            let listCopy = _.cloneDeep(list)

            if (checked) {
                set("isAllSelected", true)
                listCopy.forEach((item: any, index: number) => {
                    _.set(listCopy, `${index}.IsChecked`, 1);
                });
            } else {
                set("isAllSelected", false)
                listCopy.forEach((item: any, index: number) => {
                    _.set(listCopy, `${index}.IsChecked`, 0);
                });
            }

            set("selectedInvoices", listCopy)
        }, [JSON.stringify(list)],
    );

    const onSearchChanges = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (!newValue || newValue.length <= 5) {
                dispatch(setStore("searchKey", (newValue || '')))
            }
        }, [],
    );

    return (
        <div className="flex flex-col w-full pb-3 mt-2 gap-3">
            {/* <TextField placeholder="Search" className="w-1/2" onChange={onSearchChanges} /> */}
            <div className="flex items-center justify-between gap-3 border-b pb-3">
                <Checkbox className="ml-3" label="Select All" checked={isAllSelected} onChange={onChangeAllSelected} />

                <div className="flex items-center">
                    <p className="break-words w-24">Print types :</p>
                    <div className="flex items-center gap-3">
                        <Checkbox label="SI" checked={isSIChecked} onChange={onChangeSi} />
                        <Checkbox label="PS" checked={isPSChecked} onChange={onChangePi} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filter